import React from "react"
import styled from "styled-components"

import Layout from "../components/layout"
import SEO from "../components/seo"

const AboutContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: baseline;
`
const AboutText = styled.div`
  font-size: larger;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
`

const IndexPage = () => (
  <Layout>
    <SEO title="Home" />
    <AboutContainer>
      <h2>Hi! I'm Kushal </h2>
      <AboutText>
        <p>
          Developer, blogger : I love building things, and writing about
          how I built them.
        </p>
        <p>
          I love to experiment with new technologies, and keep learnings new
          things.
        </p>
      </AboutText>
    </AboutContainer>
  </Layout>
)

export default IndexPage
